function PhoneLine() {
  const iphoneMobile = new RegExp('iPhone');


  return (
    <div className={`phoneline ${iphoneMobile.test(navigator.userAgent) && `phoneline_iphone`}`}>
      <div className="phoneline__phone-container">
        <a className="phoneline__phone-link" href="tel: +74963446595">8(496)344-65-95</a>
        <a className="phoneline__phone-link" href="tel: +79154284888">8(915)428-48-88</a>
      </div>
    </div>
  )
}

export default PhoneLine;